.ObjectiveCard{
	padding: 18px;
	border-bottom: 1px solid var(--borderColor);
	position: relative;
}

.ObjectiveCard .desc{
	padding: 10px 0;
	padding-bottom: 30px;
}

.ObjectiveCard .title{
	font-weight: bold;
	font-size: larger;
	color: var(--neutralAccentColor);
}

.ObjectiveCard .listTitle{
	padding-bottom: 5px;
	font-weight: bold;
	font-size: 13pt;
}

.ObjectiveCard .RelatedTeam{
	padding: 5px 0;
}

.ObjectiveCard .RelatedTeam .description{
	font-style: italic;
	color: gray;
}

.ObjectiveCard .RelatedTeam .clicky:hover {
	color: var(--neutralAccentColor);
}

.ObjectiveCard .deleteButton{
	position: absolute;
	top: 10px;
	right: 20px;
}

.TeamView .uabox{
	padding-top: 50px;
}

.UnusedObjective{
	padding: 10px;
	color: gray;
	position: relative;
}

.UnusedObjective .controls{
	margin-right: 20px;
}