.Navbar .title{
	padding: 10px 10px;
	font-size: 20pt;
	font-weight: 400;
}

.Navbar{
	background-color: var(--navColor);
	color: var(--navTextColor);
}

.Navbar a:hover{
	color: white;
}